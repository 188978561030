import { Container, Row, Col } from 'react-bootstrap';
import ifpia from '../assets/img/ifpia.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { useTranslation } from 'react-i18next';
import WhatsAppForm from './WhatsAppForm';

export const Footer = () => {
  const { t, i18n } = useTranslation();

  const onChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <WhatsAppForm />
          <Col
            size={12}
            sm={6}
            onClick={() => (window.location.href = '#home')}
          >
            <img src={ifpia} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              {/* <a href="#"><img src={navIcon1} alt="Icon" /></a> */}
              <a href="https://www.facebook.com/profile.php?id=100091127346520">
                <img src={navIcon2} alt="Icon" />
              </a>
              <a href="https://wa.me/237694742399">
                <img src={navIcon3} alt="Icon" />
              </a>
              <br />
              <br />
              <select onChange={onChangeLanguage}>
                <option value="fr">Français</option>
                <option value="en">English</option>
              </select>
            </div>
            <p>
              Copyright © IFPIA 2010 - {new Date().getFullYear()}, All Rights
              Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
