import meter1 from '../assets/img/meter1.svg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from '../assets/img/color-sharp.png';
import { useTranslation } from 'react-i18next';

export const Chiffres = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const { t, i18n } = useTranslation();

  return (
    <section className="skill" id="chiffres">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>{t('NosChiffres')}</h2>
              <p>
                {t('Chiffres1')}
                <br></br> {t('Chiffres2')}
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={meter1} alt="Octroi de Visa" />
                  <h5>{t('OctroiVisa')}</h5>
                </div>
                <div className="item">
                  <img src={meter1} alt="Emplois Créés" />
                  <h5>{t('Emplois')}</h5>
                </div>
                <div className="item">
                  <img src={meter1} alt="Clients Satisfaits" />
                  <h5>{t('Clients')}</h5>
                </div>
                {/* <div className="item">
                  <img src={meter1} alt="Web Development" />
                  <h5>Web Development</h5>
                </div> */}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img
        className="background-image-left"
        src={colorSharp}
        alt="colorSharp"
      />
    </section>
  );
};
