import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from './components/NavBar';
import { Banner } from './components/Banner';
import { Chiffres } from './components/Chiffres';
import { Services } from './components/Services';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import './i18n';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Chiffres />
      <Services />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
