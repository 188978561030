import React, { useState } from 'react';
import { Col, Row, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const WhatsAppForm = () => {
  // Définir l'état local pour le message
  const [message, setMessage] = useState('');

  // Définir la fonction qui met à jour le message
  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  // Définir la fonction qui envoie le message
  const handleSubmit = (event) => {
    event.preventDefault();
    // Encoder le message pour le passer en paramètre de l'URL
    const encodedMessage = encodeURIComponent(message);
    // Ouvrir une nouvelle fenêtre avec l'URL de l'API WhatsApp
    window.open(
      `https://api.whatsapp.com/send?phone=237694742399&text=${encodedMessage}`
    );
    // Réinitialiser le message
    setMessage('');
  };

  const { t, i18n } = useTranslation();

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col lg={12} md={6} xl={5}>
            <h3>{t('LaissezUnWhatsApp')}</h3>
          </Col>
          <Col md={6} xl={7}>
            <form onSubmit={handleSubmit}>
              <div className="new-email-bx">
                <input
                  type="text"
                  value={message}
                  onChange={handleChange}
                  placeholder={t('placeholder')}
                />
                <button type="submit">{t('Envoyer')}</button>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default WhatsAppForm;
