import { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import ifpia from '../assets/img/ifpia.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  const { t, i18n } = useTranslation();

  const onChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? 'scrolled' : ''}>
        <Container>
          <Navbar.Brand href="/">
            {/* <img src={logo} alt="Logo" /> */}
            <img src={ifpia} alt="ifpia" />
            {/* <img src={require('../assets/img/ifpia.svg')} alt="Logo" /> */}
          </Navbar.Brand>
          <select onChange={onChangeLanguage}>
            <option value="fr">Français</option>
            <option value="en">English</option>
          </select>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#home"
                className={
                  activeLink === 'home' ? 'active navbar-link' : 'navbar-link'
                }
                onClick={() => onUpdateActiveLink('home')}
              >
                {t('Accueil')}
              </Nav.Link>
              <Nav.Link
                href="#chiffres"
                className={
                  activeLink === 'chiffres'
                    ? 'active navbar-link'
                    : 'navbar-link'
                }
                onClick={() => onUpdateActiveLink('chiffres')}
              >
                {t('NosChiffres')}
              </Nav.Link>
              <Nav.Link
                href="#services"
                className={
                  activeLink === 'services'
                    ? 'active navbar-link'
                    : 'navbar-link'
                }
                onClick={() => onUpdateActiveLink('services')}
              >
                {t('NosServices')}
              </Nav.Link>{' '}
              <Nav.Link
                href="https://school.ifpia.xyz"
                // target="_blank"
                className="navbar-link"
                onClick={() => onUpdateActiveLink('https://school.ifpia.xyz')}
              >
                {t('NotreEcole')}
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                {/* <a href="#">
                  <img src={navIcon1} alt="Call" />
                </a> */}
                <a
                  href="https://www.facebook.com/profile.php?id=100091127346520"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={navIcon2} alt="Facebook" />
                </a>
                <a href="https://wa.me/237694742399">
                  <img src={navIcon3} alt="WhatsApp" />
                </a>
              </div>
              <HashLink to="#connect">
                <button className="vvd">
                  <span>{t('ContactezNous')}</span>
                </button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};
