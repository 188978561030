import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

// translation catalog
const resources = {
  fr: {
    translation: {
      Accueil: 'Accueil',
      NosChiffres: 'Nos Chiffres',
      NosServices: 'Nos Services',
      NotreEcole: 'Notre École',
      ContactezNous: 'Contactez-nous',
      BienvenuechezIFPIA: 'Bienvenue chez IFPIA',
      NousImpactonsVotre: 'Nous impactons votre',
      Vie: 'Vie',
      BannerText:
        'Nous sommes passionnés par la création de solutions innovantes pour nos clients. Notre équipe d’experts est dédiée à fournir des services de qualité supérieure pour répondre aux besoins de nos clients. Contactez-nous dès aujourd’hui pour en savoir plus sur la façon dont nous pouvons vous aider à atteindre vos objectifs.',
      Chiffres1:
        'Nous sommes fiers de nos réalisations et de notre croissance continue.',
      Chiffres2: 'Voici quelques chiffres clés qui illustrent notre succès.',
      OctroiVisa: 'Octroi de Visa',
      Emplois: 'Emplois Créés',
      Clients: 'Clients Satisfaits',
      ServicesText1:
        'Nous offrons des solutions d’immigration, de formation et de prestation de services pour aider nos clients à atteindre                     leurs objectifs. Nos services sont conçus pour être flexibles et personnalisables pour répondre aux besoins                     uniques de chaque client. Nous sommes déterminés à fournir des services de qualité supérieure pour aider nos clients à réussir.',
      Formation: 'Formation',
      ServicesText2:
        'Les visas sont des documents officiels qui permettent aux voyageurs de se rendre dans un pays étranger pour une période donnée. Les types de visas varient en fonction du pays de destination et du but du voyage. Voici une liste des types de visas courants: Visa de tourisme, Visa d’affaires, Visa d’étudiant, Visa de travail, Visa de transit. Il est important de noter que les exigences en matière de visa varient d’un pays à l’autre.',
      LaissezUnWhatsApp: 'Laissez-nous un message WhatsApp',
      Envoyer: 'Envoyer',
      placeholder: 'Entrez votre message ici...',
    },
  },
  en: {
    translation: {
      Accueil: 'Home',
      NosChiffres: 'Our Figures',
      NosServices: 'Our Services',
      NotreEcole: 'Our School',
      ContactezNous: 'Contact us',
      BienvenuechezIFPIA: 'Welcome to IFPIA',
      NousImpactonsVotre: 'We impact your',
      Vie: 'Life',
      BannerText:
        'We are passionate about creating innovative solutions for our customers. Our team of experts is dedicated to providing superior quality services to meet the needs of our clients. Contact us today to learn more about how we can help you achieve your goals.',
      Chiffres1: 'We are proud of our accomplishments and continued growth.',
      Chiffres2: 'Here are some key figures that illustrate our success.',
      OctroiVisa: 'Granting of Visa',
      Emplois: 'Jobs Created',
      Clients: 'Satisfied Customers',
      ServicesText1:
        'We offer immigration, training and service delivery solutions to help our clients achieve their goals. Our services are designed to be flexible and customizable to meet the unique needs of each client. We are committed to providing superior services to help our clients succeed.',
      Formation: 'Training',
      ServicesText2:
        'Visas are official documents that allow travelers to visit a foreign country for a specific period of time. Visa types vary depending on the destination country and purpose of travel. Here is a list of common visa types: Tourist Visa, Business Visa, Student Visa, Work Visa, Transit Visa. It is important to note that visa requirements vary from country to country.',
      LaissezUnWhatsApp: 'Leave us a WhatsApp message',
      Envoyer: 'Send',
      placeholder: 'Enter your message here...',
    },
  },
};

// initialize i18next with catalog and language to use
i18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
