import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';
import developpementmobile from '../assets/img/developpement-mobile.webp';
import developpementweb from '../assets/img/developpement-web.webp';
import maintenanceinformatique from '../assets/img/maintenance-informatique.webp';
import marketingdigital from '../assets/img/marketing-digital.webp';
import securiteinformatique from '../assets/img/securite-informatique.webp';
import audiovisuel from '../assets/img/audio-visuel.webp';
import bigdata from '../assets/img/big-data.webp';
import comptabiliteinformatisee from '../assets/img/comptabilite-informatisee.webp';
import entrepreneuriat from '../assets/img/entrepreneuriat.webp';
import bureautique from '../assets/img/formation-bureautique.webp';
import camerasurveillance from '../assets/img/camerasurveillance.webp';
import reseau from '../assets/img/reseau.webp';
import colorSharp2 from '../assets/img/color-sharp2.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation } from 'react-i18next';

export const Services = () => {
  const formations = [
    {
      title: 'Développement Mobile',
      description: 'Développement d’applications mobiles pour Android et iOS',
      imgUrl: developpementmobile,
    },
    {
      title: 'Développement Web',
      description:
        'Conception et développement de sites Web dynamiques et interactifs',
      imgUrl: developpementweb,
    },
    {
      title: 'Maintenance Informatique',
      description: 'Diagnostic et résolution des problèmes informatiques',
      imgUrl: maintenanceinformatique,
    },
    {
      title: 'Marketing Digital',
      description:
        'Utilisation des outils numériques pour promouvoir les entreprises',
      imgUrl: marketingdigital,
    },
    {
      title: 'Sécurité Informatique',
      description:
        'Protection des personnes/entreprises contre les menaces informatiques',
      imgUrl: securiteinformatique,
    },
    {
      title: 'Audio-Visuel',
      description:
        'Production de contenus audiovisuels de qualité professionnelle',
      imgUrl: audiovisuel,
    },
    {
      title: 'Big Data',
      description: 'Gestion et analyse de grandes quantités de données',
      imgUrl: bigdata,
    },
    {
      title: 'Comptabilité Informatisée',
      description:
        'Utilisation de logiciels de comptabilité pour gérer les finances des entreprises',
      imgUrl: comptabiliteinformatisee,
    },
    {
      title: 'Entrepreneuriat',
      description: 'Création et gestion des entreprises',
      imgUrl: entrepreneuriat,
    },
    {
      title: 'Bureautique',
      description:
        'Utilisation des outils bureautiques tels que Microsoft Office',
      imgUrl: bureautique,
    },
  ];

  const services = [
    {
      title: 'Caméra de surveillance',
      description: 'Système de sécurité pour surveiller les lieux',
      imgUrl: camerasurveillance,
    },
    {
      title: 'Réseau',
      description:
        'Mise en place de connexions au moyen de câbles informatiques & WiFi',
      imgUrl: reseau,
    },
    {
      title: 'Développement Mobile',
      description: 'Développement d’applications mobiles pour Android et iOS',
      imgUrl: developpementmobile,
    },
    {
      title: 'Développement Web',
      description:
        'Conception et développement de sites Web dynamiques et interactifs',
      imgUrl: developpementweb,
    },
    {
      title: 'Maintenance Informatique',
      description: 'Diagnostic et résolution des problèmes informatiques',
      imgUrl: maintenanceinformatique,
    },
    {
      title: 'Marketing Digital',
      description:
        'Utilisation des outils numériques pour promouvoir les entreprises',
      imgUrl: marketingdigital,
    },
    {
      title: 'Sécurité Informatique',
      description:
        'Protection des personnes/entreprises contre les menaces informatiques',
      imgUrl: securiteinformatique,
    },
    {
      title: 'Audio-Visuel',
      description:
        'Production de contenus audiovisuels de qualité professionnelle',
      imgUrl: audiovisuel,
    },
    {
      title: 'Big Data',
      description: 'Gestion et analyse de grandes quantités de données',
      imgUrl: bigdata,
    },
  ];

  const { t, i18n } = useTranslation();

  return (
    <section className="project" id="services">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? 'animate__animated animate__fadeIn' : ''
                  }
                >
                  <h2>{t('NosServices')}</h2>
                  <p>{t('ServicesText1')}</p>
                  <Tab.Container id="services-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Immigration</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">{t('Formation')}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Services</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? 'animate__animated animate__slideInUp' : ''
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <p>{t('ServicesText2')}</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {formations.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {services.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img
        className="background-image-right"
        src={colorSharp2}
        alt="colorSharp2"
      />
    </section>
  );
};
